<template>
    <v-container class="px-0 pt-0">
        <template-save-dialog
            :dialog="dialogs.save_bundle_template_dialog"
            @submit_template="save_bundle_template"
        />
        <open-bundle-template-dialog
            :dialog="dialogs.open_bundle_template_dialog"
            @set_template="set_template"
        />
        <v-row class="" v-if="status == 'rejected_bundle'">
            <v-col cols="12">
                <v-container class="">
                    <v-row class="pa-2">
                        <v-card
                            color="pink lighten-4"
                            class="red--text"
                            width="100%"
                        >
                            <v-card-title
                                class="text-h5"
                                style="font-family: 'Poppins'; font-weight: 800"
                            >
                                {{$tr("Your Bundle Has Been Rejected")}}
                                {{
                                    rejection_note
                                        ? "with the following notes"
                                        : ""
                                }}
                            </v-card-title>

                            <v-card-subtitle
                                style="color: #4a4646"
                                v-if="rejection_note"
                            >
                                {{ rejection_note }}
                            </v-card-subtitle>
                        </v-card>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="">
            <v-col cols="12" class="px-0 py-0">
                <slide-buttons v-model="day" :items="items" />
            </v-col>
        </v-row>
        <v-row class="">
            <v-col cols="12" class="py-0">
                <v-container class="py-0">
                    <v-row>
                        <v-spacer></v-spacer>
                        <add-task-dialog @add="loading || add_task($event)" />
                    </v-row>
                    <v-row
                        class="pa-2"
                        v-for="(task, index) in chosen_day_tasks"
                        :key="index"
                    >
                        <task-view
						    :no_heading="true"
                            :task="task"
                            :events="task_view_events"
                            @delete="chosen_day_tasks.splice(index, 1)"
                        />
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-container class="pt-4">
                    <v-row class="m-x-between">
                        <v-spacer></v-spacer>
                        <v-btn
                            class="primary"
                            fab
                            @click="
                                dialogs.open_bundle_template_dialog.show = true
                            "
                            :disabled="loading"
                            :loading="loading"
                        >
                            <v-icon>mdi-folder-open</v-icon>
                        </v-btn>
                        <v-btn
                            class="primary"
                            fab
                            @click="
                                dialogs.save_bundle_template_dialog.show = true
                            "
                            :disabled="!valid_bundle || loading"
                            :loading="loading"
                        >
                            <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                        <v-btn
                            class="primary"
                            fab
                            @click="save_bundle"
                            :disabled="!valid_bundle || loading"
                            :loading="loading"
                        >
                            <v-icon>mdi-cloud-check</v-icon>
                        </v-btn>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Api from "@/api/api/index.js";
export default {
    data: () => ({
        task_view_events: [
            {
                event: "delete",
                class: "error--text",
                title: "DELETE",
            },
        ],
        work_days: [],
        day: null,
        loading: false,
        dialogs: {
            save_bundle_template_dialog: {
                show: false,
            },
            open_bundle_template_dialog: {
                show: false,
            },
        },
    }),
    methods: {
        set_template(days) {
            if (Array.isArray(days)) {
                for (const [index, day] of Object.entries(this.days)) {
                    this.$set(this.days, index, days[index]);
                }
                this.$set(this, "day", this.days?.[0]?.date);
            } else {
                console.error("setting template days is not array", days);
            }
        },
        async save_bundle() {
            this.loading = true;
            try {
                const result = (
                    await Api.post(
                        this.this_week
                            ? "task/register/bundle_this_week"
                            : "task/register/bundle_next_week",
                        { days: this.days }
                    )
                ).data.result;
                console.log(result);
                this.loading = false;
                let notification = {
                    msg: "Bundle Has Been Saved Successfully.",
                    color: "success",
                };
                localStorage.removeItem("aram@mstats@tmapp@createbundle@days");
                this.$emit("refresh");
                await this.$store.dispatch("user/notify", notification);
            } catch (err) {
                console.log(err);
                this.loading = false;
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        "Error, please try again later",
                    color: "error",
                };
                await this.$store.dispatch("user/notify", notification);
            }
        },
        async save_bundle_template(bundle) {
            this.loading = true;
            try {
                const result = (
                    await Api.post("task/register/bundle_template", {
                        days: this.days,
                        name: bundle.name,
                    })
                ).data.result;
                this.loading = false;
                let notification = {
                    msg: "Bundle Template Has Been Saved Successfully.",
                    color: "success",
                };
                await this.$store.dispatch("user/notify", notification);
            } catch (err) {
                console.log(err);
                this.loading = false;
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        "Error, please try again later",
                    color: "error",
                };
                await this.$store.dispatch("user/notify", notification);
            }
        },
        add_task(task) {
            console.log("why not", task);
            const chosen_day = this.days.filter((el) => el.date == this.day)[0];
            Array.isArray(chosen_day?.tasks) ||
                this.$set(chosen_day, "tasks", []);
            chosen_day.tasks.push(task);
        },
    },
    props: {
        status: {
            type: String,
            default: "create",
        },
        rejection_note: "",
        days: null,
        this_week: null,
    },
    async mounted() {
        try {
            const work_days = (
                    await Api.post("task/fetch/work_days", null, {
                        sinceMins: 30, 
                        now: false, 
                    })).data
                .work_days;
            this.$set(this, "work_days", work_days);
        } catch (error) {
            console.log("error", error);
        }
        const saved_days = JSON.parse(
            localStorage.getItem("aram@mstats@tmapp@createbundle@days")
        );

        console.log("loaded saved shit", saved_days);
        if (saved_days) {
            for (const [index, day] of Object.entries(this.days)) {
                this.$set(day, "tasks", saved_days[index]?.tasks);
            }
            console.log(this.days?.[0]?.date);
        }
        this.$set(this, "day", this.days?.[0]?.date);
    },
    beforeDestroy() {
        localStorage.setItem(
            "aram@mstats@tmapp@createbundle@days",
            JSON.stringify(this.days)
        );
    },
    computed: {
        valid_bundle() {
            let condition = true;
            for (const day of this.days) {
                condition &=
                    day.tasks?.length >= day.minimum_tasks_per_day ||
                    !this.work_days.includes(day.dayofweek_index);
            }
            return condition;
        },
        chosen_day_tasks() {
            const returned =
                this.days?.filter((el) => el.date == this.day)[0]?.tasks || [];
            return returned;
        },
        items() {
            return this.days
                ?.filter((day) => {
                    return this.work_days.includes(day.dayofweek_index);
                })
                .map((el) => ({ title: el.date, subtitle: el.dayofweek }));
        },
    },
    components: {
        SlideButtons: () =>
            import(
                /* webpackChunkName: "workday_no_tasks" */
                "./components/slide_buttons.vue"
            ),
        OpenBundleTemplateDialog: () =>
            import(
                /* webpackChunkName: "OpenBundleTemplateDialog" */
                "./components/OpenBundleTemplateDialog.vue"
            ),
        TemplateSaveDialog: () =>
            import(
                /* webpackChunkName: "TemplateSaveDialog" */
                "./components/TemplateSaveDialog.vue"
            ),
        AddTaskDialog: () =>
            import(
                /* webpackChunkName: "workday_no_tasks" */
                "./components/add_task.vue"
            ),
        TaskView: () =>
            import(
                /* webpackChunkName: "task" */
                "./components/task.vue"
            ),
    },
};
</script>

<style lang="sass">
.m-x-between
	& *
		margin: 0px 4px
</style>
